import React from "react";
import CSR from "../components/FrontStatic/Csr"
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const CSRPage = (props) =>{
    return(
        <React.Fragment>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <CSR {...props} fields={props?.data?.[props.commonData.locale.locale]?.frontmatter} />
        </React.Fragment>
    )
};

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CSRPage);

export const query = graphql`
fragment CSRFragment on MarkdownRemark {
       frontmatter {
      title
      description
      lists{
        title
        description
        img{
            publicURL
        }
      }
    }
  }
  query CSRPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "csr-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  en: markdownRemark(frontmatter: {templateKey: {eq: "en-csr"}}) {
   ...CSRFragment
  }
  de: markdownRemark(frontmatter: {templateKey: {eq: "de-csr"}}) {
   ...CSRFragment
  }
  es: markdownRemark(frontmatter: {templateKey: {eq: "es-csr"}}) {
   ...CSRFragment
  }
  he: markdownRemark(frontmatter: {templateKey: {eq: "he-csr"}}) {
   ...CSRFragment
  }
  fr: markdownRemark(frontmatter: {templateKey: {eq: "fr-csr"}}) {
   ...CSRFragment
  }
  it: markdownRemark(frontmatter: {templateKey: {eq: "it-csr"}}) {
   ...CSRFragment
  }
  ja: markdownRemark(frontmatter: {templateKey: {eq: "ja-csr"}}) {
   ...CSRFragment
  }
  zh: markdownRemark(frontmatter: {templateKey: {eq: "zh-csr"}}) {
   ...CSRFragment
  }
 }
`;